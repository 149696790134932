import { Injectable } from '@angular/core';

import { internalUserGuard } from '../internal-user-guard.decorator';
import { PianoEventData, PianoUniversalData, PianoUtag } from '../model/piano.models';

const WINDOW = window as any;

@Injectable({
  providedIn: 'root',
})
export class CoreAnalyticsService {
  protected defaultData: PianoUniversalData = this.getDefaults();

  // Return window.utag if it's defined, otherwise wait 500ms and try again, after 20 tries throw an error
  public get utag(): Promise<PianoUtag> {
    return new Promise((resolve, reject) => {
      let tries = 0;

      const utag = WINDOW.utag as PianoUtag | undefined;

      if (utag && utag.data) {
        resolve(utag);
      } else {
        const interval = setInterval(() => {
          const utag = WINDOW.utag as PianoUtag | undefined;

          if (utag && utag.data) {
            clearInterval(interval);
            resolve(utag);
          } else if (++tries > 20) {
            clearInterval(interval);
            reject(
              Error('[AnalyticsService::utag], utag or utag.data is undefined after 20 tries'),
            );
          }
        }, 500);
      }
    });
  }

  protected getDefaults(): PianoUniversalData {
    return {
      page_type: 'website',
      page_country: 'global',
      page_language: 'en',
      page_name: undefined,
      page_category: undefined,
      customer_type: undefined,
      event_category: undefined,
      event_action: undefined,
      event_type: undefined,
      event_value: undefined,
      event_goal: undefined,
      product_name: undefined,
      product_id: undefined,
      search_term: undefined,
      page_privacy_policy: undefined,
      business_partner_ship_to: undefined,
      business_partner_sold_to: undefined,
    };
  }

  // Called on logout from the authenticationService
  public async clearData(): Promise<void> {
    this.defaultData = this.getDefaults();
    // Wait to be sure, that utag is defined
    const utag = await this.utag;
    Object.entries(this.defaultData).forEach(([key, value]) => {
      utag.data[key as keyof PianoUniversalData] = value as any;
    });
  }

  @internalUserGuard
  protected async trackView(utagData: PianoUniversalData): Promise<void> {
    const completeUtagData = {
      ...this.defaultData,
      ...utagData,
    };

    const utag = await this.utag;
    utag.view(completeUtagData);
  }

  @internalUserGuard
  protected async trackLink(utagData: PianoEventData): Promise<void> {
    const completeUtagData = {
      ...this.defaultData,
      ...utagData,
    };

    const utag = await this.utag;
    utag.link(completeUtagData);
  }
}
