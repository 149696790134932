/* eslint-disable @typescript-eslint/ban-types */
const WINDOW = window as any;

/**
 * Decorator to guard a method of a service from being called by internal users.
 */
export function internalUserGuard(
  target: any,
  propertyKey: string,
  descriptor: PropertyDescriptor,
) {
  const originalMethod = descriptor.value;

  descriptor.value = function (...args: any[]) {
    // globalData is defined when login happens using the global-data.service.ts
    const isInternalUser = !!WINDOW.globalData?.isInternalUser;

    if (!isInternalUser) {
      const result = originalMethod.apply(this, args);
      return result;
    }

    console.warn(`Method ${propertyKey} is not called because the user is internal.`);
    return;
  };

  return descriptor;
}
